import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hero = _resolveComponent("Hero")!
  const _component_Partners = _resolveComponent("Partners")!
  const _component_Information = _resolveComponent("Information")!
  const _component_About = _resolveComponent("About")!
  const _component_Steps = _resolveComponent("Steps")!
  const _component_Action = _resolveComponent("Action")!
  const _component_Reviews = _resolveComponent("Reviews")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Hero),
    _createVNode(_component_Partners),
    _createVNode(_component_Information),
    _createVNode(_component_About),
    _createVNode(_component_Steps),
    _createVNode(_component_Action),
    _createVNode(_component_Reviews),
    _createVNode(_component_Footer)
  ], 64))
}