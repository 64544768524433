<template>
  <section class="about">
    <div class="container">
      <h2 class="about__title">המלצתנו</h2>
      <p class="about__text">
        מתכננים טיול לבחריין? הגישו בקשה לויזה בהקדם וחסכו זמן וכסף
        והרבה כאבי ראש מיותרים בשדה התעופה
      </p>
      <ul class="about__list">
        <li class="about__list-item">
          <img
            src="/img/svgs/wall-clock.svg"
            alt="clock"
            class="about__list-item-image"
          />
          תוקף הויזה למשך 90 יום מיום ההנפקה
        </li>
        <li class="about__list-item">
          <img
            src="/img/svgs/location.svg"
            alt="location"
            class="about__list-item-image"
          />
          יכול לשמש לשהייה בבחריין עד 14 יום.
        </li>
      </ul>
    </div>
  </section>
</template>