<template>
  <section class="steps">
    <div class="container">
      <h2 class="steps__title">אז איך זה עובד?</h2>
      <p class="steps__text">
        אתם שולחים הודעה לבוט הוואטסאפ האוטומטי, משם בקשתכם עוברת לרשות להנפקת
        ויזות ותוך יום עסקים תקבלו את הויזה ישירות לוואטסאפ שלכם.
      </p>
      <p class="steps__text">כל מה שאתם צריכים לעשות הוא מספר פעולות פשוטות:</p>
      <ul class="steps__list">
        <img
          src="/img/svgs/whatsapp1.svg"
          alt="icon"
          class="steps__list-icon steps__list-icon--1"
        />
        <img
          src="/img/svgs/whatsapp2.svg"
          alt="icon"
          class="steps__list-icon steps__list-icon--2"
        />
        <img
          src="/img/svgs/whatsapp3.svg"
          alt="icon"
          class="steps__list-icon steps__list-icon--3"
        />
        <li class="steps__list-item">שלחו הודעה לוואטסאפ</li>
        <li class="steps__list-item">- העלו תמונה של צילום הדרכון שלכם<br>- צרף כרטיס טיסה חזור<br>- צרף הזמנת מלון</li>
        <li class="steps__list-item">שלמו</li>
        <li class="steps__list-item">הויזה אצלכם בוואטסאפ תוך יום עסקים אחד</li>
      </ul>
    </div>
  </section>
</template>