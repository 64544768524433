<template>
  <section class="information">
    <div class="container">
      <h2 class="information__subtitle">
        כל נוסע המחזיק בדרכון ישראלי חייב בויזה על מנת להיכנס לבחריין.
        <br />
        <br />השירות שלנו מציע לכם להזמין ויזה מהירה כאן ועכשיו בלא צורך במילוי
        טפסים, ובמחיר הכי זול בישראל. <br />
        <br />והכל קורה דרך הוואטסאפ שלכם תוך שניות! <br />
        <br />הדרך המהירה ביותר ללא צורך בסוכןֿ.
        <br />
        <br />הוויזה מגיעה לוואטסאפ שלכם בדרך כלל עד יום עסקים אחד.
        <br />
        <br />
        <a href="http://wa.link/tpxp5a" class="header-top__button button">
          להזמנת ויזה מהירה לבחריין לחץ כאן
        </a>
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Information'
}
</script>