
import { defineComponent } from "vue";
import siteConfig from "../site-config.json";

export default defineComponent({
  setup() {
    return {
      header: siteConfig.header,
      cta_text: siteConfig.cta_text
    };
  },
});
