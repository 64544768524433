<template>
  <section class="partners">
    <div class="container">
      <h2 class="partners__title">השותפים שלנו</h2>
      <div class="wrapper">
        <div
          class="
            partners__slider
            swiper-container
            swiper-container-initialized
            swiper-container-horizontal
            swiper-container-pointer-events
          "
        >
          <div
            class="swiper-wrapper partners__slider-wrapper"
            id="swiper-wrapper-bc926138eca48ba10"
            aria-live="polite"
            style="
              transition-duration: 0ms;
              transform: translate3d(-748px, 0px, 0px);
            "
          >
            <div
              class="swiper-slide partners__slider-slide swiper-slide-duplicate"
              data-swiper-slide-index="3"
              role="group"
              aria-label="1 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/arkia-1.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="swiper-slide partners__slider-slide swiper-slide-duplicate"
              data-swiper-slide-index="4"
              role="group"
              aria-label="2 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/expo-1.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="swiper-slide partners__slider-slide swiper-slide-duplicate"
              data-swiper-slide-index="5"
              role="group"
              aria-label="3 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/gulf.png"
                alt="Partner's logo"
              />
            </div>
            <div
              class="
                swiper-slide
                partners__slider-slide
                swiper-slide-duplicate swiper-slide-prev
              "
              data-swiper-slide-index="6"
              role="group"
              aria-label="4 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/fly-dubai-1.webp"
                alt="Partner's logo"
              />
            </div>
            <!-- Slides -->

            <div
              class="swiper-slide partners__slider-slide swiper-slide-active"
              data-swiper-slide-index="0"
              role="group"
              aria-label="5 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/israil-copy.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="swiper-slide partners__slider-slide swiper-slide-next"
              data-swiper-slide-index="1"
              role="group"
              aria-label="6 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/emirates.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="swiper-slide partners__slider-slide"
              data-swiper-slide-index="2"
              role="group"
              aria-label="7 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/el-al.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="swiper-slide partners__slider-slide"
              data-swiper-slide-index="3"
              role="group"
              aria-label="8 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/arkia-1.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="swiper-slide partners__slider-slide"
              data-swiper-slide-index="4"
              role="group"
              aria-label="9 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/expo-1.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="swiper-slide partners__slider-slide"
              data-swiper-slide-index="5"
              role="group"
              aria-label="10 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/gulf.png"
                alt="Partner's logo"
              />
            </div>
            <div
              class="
                swiper-slide
                partners__slider-slide
                swiper-slide-duplicate-prev
              "
              data-swiper-slide-index="6"
              role="group"
              aria-label="11 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/fly-dubai-1.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="
                swiper-slide
                partners__slider-slide
                swiper-slide-duplicate swiper-slide-duplicate-active
              "
              data-swiper-slide-index="0"
              role="group"
              aria-label="12 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/israil-copy.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="
                swiper-slide
                partners__slider-slide
                swiper-slide-duplicate swiper-slide-duplicate-next
              "
              data-swiper-slide-index="1"
              role="group"
              aria-label="13 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/emirates.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="swiper-slide partners__slider-slide swiper-slide-duplicate"
              data-swiper-slide-index="2"
              role="group"
              aria-label="14 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/el-al.webp"
                alt="Partner's logo"
              />
            </div>
            <div
              class="swiper-slide partners__slider-slide swiper-slide-duplicate"
              data-swiper-slide-index="3"
              role="group"
              aria-label="15 / 15"
              style="width: 167px; margin-right: 20px"
            >
              <img height="80" width="150"
                src="/img/logos/arkia-1.webp"
                alt="Partner's logo"
              />
            </div>
          </div>
          <span
            class="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          ></span>
        </div>
        <div
          class="swiper-button-prev reviews__slider-prev"
          tabindex="0"
          role="button"
          aria-label="Previous slide"
          aria-controls="swiper-wrapper-bc926138eca48ba10"
        ></div>
        <div
          class="swiper-button-next reviews__slider-next"
          tabindex="0"
          role="button"
          aria-label="Next slide"
          aria-controls="swiper-wrapper-bc926138eca48ba10"
        ></div>
      </div>
    </div>
  </section>
</template>
