<template>
  <footer class="footer">
		<section class="contact">
			<div class="container">
				<p class="address">{{company_name}}</p>
				<p class="phone">
					Phone: <a :href="'tel:'+phone">{{phone}}</a>
				</p>
				<p class="mail">
					Email: <a :href="'mailto:'+email">{{email}}</a>
				</p>
				<div class="logo">
					<img src="/img/svgs/light-logo.svg" alt="logo">
				</div>
			</div>
		</section>

		<section class="copyright">
			<div class="container">
				<p class="copyright__text">
					{{copyright_message}}
				</p>
			</div>
		</section>
	</footer>
</template>

<script>
import { defineComponent } from "vue";
import data from "../site-config.json";

export default defineComponent({
  setup() {
    return {
      company_name: data.company_name,
      phone: data.phone,
      email: data.email,
      copyright_message: data.copyright_message,
    };
  },
});
</script>
