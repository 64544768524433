<template>
  <section class="reviews">
    <div class="container">
      <h2 class="reviews__title">הממליצים שלנו</h2>
    </div>
    <div class="container">
      <div
        class="
          swiper-container
          reviews__slider
          swiper-container-initialized
          swiper-container-horizontal
          swiper-container-pointer-events
        "
      >
        <div
          class="swiper-wrapper reviews__slider-wrapper"
          id="swiper-wrapper-86f641dc7655d269"
          aria-live="polite"
          style="
            transition-duration: 0ms;
            transform: translate3d(-883px, 0px, 0px);
          "
        >
          <div
            class="swiper-slide swiper-slide-duplicate"
            data-swiper-slide-index="4"
            role="group"
            aria-label="1 / 10"
            style="width: 399.5px; margin-right: 42px"
          >
            <div class="reviews__slider-slide">
              <div class="reviews__slider-slide-information">
                <img
                  src="/img/images/profile-photo.webp"
                  alt="Testimonial author's photo"
                  class="reviews__slider-slide-photo"
                />
                <h3 class="reviews__slider-slide-name">יוסף גורביץ</h3>
                <div class="reviews__slider-slide-position">לקוח</div>
              </div>
              <p>
                - שבוע לפני טיול משפחתי לבחריין גילינו שלא עשינו ויזות. הופתעתי
                מהיעילות והפשטות של תהליך הוצאת הויזות דרך הבוט. הוצאנו ויזות
                לכל המשפחה בתוך מספר דקות וקיבלנו אותן ישר לווצאפ תוך 5 שעות.
                מקסים.
              </p>
            </div>
          </div>
          <div
            class="swiper-slide swiper-slide-duplicate swiper-slide-prev"
            data-swiper-slide-index="5"
            role="group"
            aria-label="2 / 10"
            style="width: 399.5px; margin-right: 42px"
          >
            <div class="reviews__slider-slide">
              <div class="reviews__slider-slide-information">
                <img
                  src="/img/images/profile-photo.webp"
                  alt="Testimonial author's photo"
                  class="reviews__slider-slide-photo"
                />
                <h3 class="reviews__slider-slide-name">איתן</h3>
                <div class="reviews__slider-slide-position">
                  מנהל חברת תיירות
                </div>
              </div>
              <p>
                - בתור אחד שבורח מבירוקטרטיה, הבוט מושלם בשבילי! בלי לדבר עם אף
                פקיד, תוך כמה דקות וזה מאחוריי.
              </p>
            </div>
          </div>
          <!-- Slides -->

          <div
            class="swiper-slide swiper-slide-active"
            data-swiper-slide-index="0"
            role="group"
            aria-label="3 / 10"
            style="width: 399.5px; margin-right: 42px"
          >
            <div class="reviews__slider-slide">
              <div class="reviews__slider-slide-information">
                <img
                  src="/img/images/profile-photo.webp"
                  alt="Testimonial author's photo"
                  class="reviews__slider-slide-photo"
                />
                <h3 class="reviews__slider-slide-name">יונתן</h3>
                <div class="reviews__slider-slide-position">
                  לקוח
                </div>
              </div>
              <p>
                תהליך להוצאת ויזה הכי פשוט נוח ומהיר שפגשנו בו. הכרטיס אשראי שלנו לא עבר באתר הממשלתי אבל מערכת הבוט פצצה. מליץ בחום
              </p>
            </div>
          </div>
          <div
            class="swiper-slide swiper-slide-next"
            data-swiper-slide-index="1"
            role="group"
            aria-label="4 / 10"
            style="width: 399.5px; margin-right: 42px"
          >
            <div class="reviews__slider-slide">
              <div class="reviews__slider-slide-information">
                <img
                  src="/img/images/Screen-Shot-2021-07-12-at-11.55.39-PM.webp"
                  alt="Testimonial author's photo"
                  class="reviews__slider-slide-photo"
                />
                <h3 class="reviews__slider-slide-name">שלי זוהר</h3>
                <div class="reviews__slider-slide-position">
                  בלוגרית ומנהלת חברת Time 4 Dubai
                </div>
              </div>
              <p>
                אני הרבה על קו ישראל- דובאי, כך שאני מכירה את תהליך הויזות
                ויודעת שהוא לוקח זמן. באחת הפעמים החלטתי לנסות את הבוט והוא היה
                סופר יעיל! קיבלתי את הויזה תוך מספר שעות, המענה היה מהיר, השירות
                מעולה. מאז אני ממליצה ללקוחות שלי לסגור רק דרך הבוט בתהליך מהיר
                ופשוט- אין סיבה להסתבך עם הויזה!
              </p>
            </div>
          </div>
          <div
            class="swiper-slide"
            data-swiper-slide-index="2"
            role="group"
            aria-label="5 / 10"
            style="width: 399.5px; margin-right: 42px"
          >
            <div class="reviews__slider-slide">
              <div class="reviews__slider-slide-information">
                <img
                  src="/img/images/profile-photo.webp"
                  alt="Testimonial author's photo"
                  class="reviews__slider-slide-photo"
                />
                <h3 class="reviews__slider-slide-name"></h3>
                <div class="reviews__slider-slide-position">Customer</div>
              </div>
              <p>
                Thanks that was very fast Very professional
                <img
                  draggable="false"
                  role="img"
                  class="emoji"
                  alt="👍"
                  src="/img/svgs/1f44d.svg"
                />
                Got my visa in 2 hours!!!!:)
              </p>
            </div>
          </div>
          <div
            class="swiper-slide"
            data-swiper-slide-index="3"
            role="group"
            aria-label="6 / 10"
            style="width: 399.5px; margin-right: 42px"
          >
            <div class="reviews__slider-slide">
              <div class="reviews__slider-slide-information">
                <img
                  src="/img/images/Screen-Shot-2021-07-12-at-11.46.31-PM.webp"
                  alt="Testimonial author's photo"
                  class="reviews__slider-slide-photo"
                />
                <h3 class="reviews__slider-slide-name">אפרים קמיסר</h3>
                <div class="reviews__slider-slide-position">
                  המאסטר של דובאי
                </div>
              </div>
              <p>
                - וואו, התהליך הכי מהיר וקל שיש אנחנו ממליצים בחום ללקוחות שלנו!
                שירות נוח ומהיר תודה!
              </p>
            </div>
          </div>
          <div
            class="swiper-slide"
            data-swiper-slide-index="4"
            role="group"
            aria-label="7 / 10"
            style="width: 399.5px; margin-right: 42px"
          >
            <div class="reviews__slider-slide">
              <div class="reviews__slider-slide-information">
                <img
                  src="/img/images/profile-photo.webp"
                  alt="Testimonial author's photo"
                  class="reviews__slider-slide-photo"
                />
                <h3 class="reviews__slider-slide-name">יוסף גורביץ</h3>
                <div class="reviews__slider-slide-position">לקוח</div>
              </div>
              <p>
                - שבוע לפני טיול משפחתי לדובאי גילינו שלא עשינו ויזות. הופתעתי
                מהיעילות והפשטות של תהליך הוצאת הויזות דרך הבוט. הוצאנו ויזות
                לכל המשפחה בתוך מספר דקות וקיבלנו אותן ישר לווצאפ תוך 5 שעות.
                מקסים.
              </p>
            </div>
          </div>
          <div
            class="swiper-slide swiper-slide-duplicate-prev"
            data-swiper-slide-index="5"
            role="group"
            aria-label="8 / 10"
            style="width: 399.5px; margin-right: 42px"
          >
            <div class="reviews__slider-slide">
              <div class="reviews__slider-slide-information">
                <img
                  src="/img/images/profile-photo.webp"
                  alt="Testimonial author's photo"
                  class="reviews__slider-slide-photo"
                />
                <h3 class="reviews__slider-slide-name">איתן</h3>
                <div class="reviews__slider-slide-position">
                  מנהל חברת תיירות
                </div>
              </div>
              <p>
                - בתור אחד שבורח מבירוקטרטיה, הבוט מושלם בשבילי! בלי לדבר עם אף
                פקיד, תוך כמה דקות וזה מאחוריי.
              </p>
            </div>
          </div>
          <div
            class="
              swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active
            "
            data-swiper-slide-index="0"
            role="group"
            aria-label="9 / 10"
            style="width: 399.5px; margin-right: 42px"
          >
            <div class="reviews__slider-slide">
              <div class="reviews__slider-slide-information">
                <img
                  src="/img/images/FC2A07D8-EDE3-4D53-B14C-1577EDA5C1A7.webp"
                  alt="Testimonial author's photo"
                  class="reviews__slider-slide-photo"
                />
                <h3 class="reviews__slider-slide-name">עופר</h3>
                <div class="reviews__slider-slide-position">
                  מנהל ישראל דובאי תיירות
                </div>
              </div>
              <p>
                תהליך להוצאת ויזה הכי פשוט נוח ומהיר שפגשנו בו. כמובן שיש להם
                שירות לקוחות לכל בעיה. אנחנו ממליצים בחום

                <a href="https://israel-dubai.co.il"
                  >https://israel-dubai.co.il</a
                >
              </p>
            </div>
          </div>
          <div
            class="
              swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next
            "
            data-swiper-slide-index="1"
            role="group"
            aria-label="10 / 10"
            style="width: 399.5px; margin-right: 42px"
          >
            <div class="reviews__slider-slide">
              <div class="reviews__slider-slide-information">
                <img
                  src="/img/images/Screen-Shot-2021-07-12-at-11.55.39-PM.webp"
                  alt="Testimonial author's photo"
                  class="reviews__slider-slide-photo"
                />
                <h3 class="reviews__slider-slide-name">שלי זוהר</h3>
                <div class="reviews__slider-slide-position">
                  בלוגרית ומנהלת חברת Time 4 Dubai
                </div>
              </div>
              <p>
                אני הרבה על קו ישראל- דובאי, כך שאני מכירה את תהליך הויזות
                ויודעת שהוא לוקח זמן. באחת הפעמים החלטתי לנסות את הבוט והוא היה
                סופר יעיל! קיבלתי את הויזה תוך מספר שעות, המענה היה מהיר, השירות
                מעולה. מאז אני ממליצה ללקוחות שלי לסגור רק דרך הבוט בתהליך מהיר
                ופשוט- אין סיבה להסתבך עם הויזה!
              </p>
            </div>
          </div>
        </div>

        <!-- If we need navigation buttons -->
        <div class="swiper-button-container">
          <div
            class="swiper-button-prev reviews__slider-prev"
            tabindex="0"
            role="button"
            aria-label="Previous slide"
            aria-controls="swiper-wrapper-bc926138eca48ba10"
          ></div>
          <div
            class="swiper-button-next reviews__slider-next"
            tabindex="0"
            role="button"
            aria-label="Next slide"
            aria-controls="swiper-wrapper-bc926138eca48ba10"
          ></div>
        </div>
        <span
          class="swiper-notification"
          aria-live="assertive"
          aria-atomic="true"
        ></span>
      </div>
    </div>
  </section>
</template>