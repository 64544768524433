
import { defineComponent } from "vue";
import Footer from "./components/Footer.vue";
import Reviews from "./components/Reviews.vue";
import Action from "./components/Action.vue";
import About from "./components/About.vue";
import Information from "./components/Information.vue";
import Partners from "./components/Partners.vue";
import Hero from "./components/Hero.vue";
import Steps from "./components/Steps.vue";
import Swiper, {Navigation} from "swiper"
import "swiper/swiper.min.css";
import siteConfig from "./site-config.json";

export default defineComponent({
  name: "App",
  components: {
    Steps,
    Hero,
    Partners,
    Information,
    Footer,
    Reviews,
    Action,
    About
  },
  mounted(){
    Swiper.use([Navigation])
    new Swiper('.reviews__slider', {
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.reviews__slider .swiper-button-next',
        prevEl: '.reviews__slider .swiper-button-prev'
      },
      breakpoints: {
        769: {
          slidesPerView: 2,
          spaceBetween: 42
        }
      }
    });

    new Swiper('.partners__slider', {
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.partners .swiper-button-next',
        prevEl: '.partners .swiper-button-prev'
      },
      breakpoints: {
        540: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        769: {
          slidesPerView: 4,
          spaceBetween: 20
        }
      }
    });
    window.document.title = siteConfig.title
  }
});
